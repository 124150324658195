var ui_scroll = (function(document) {

	var evt = [

		function(document) {

			var header = document.querySelector('header');

			let scroll = window.scrollY;
			if (scroll > 50) {
					
				header.classList.add('scrolled');
			} else {

				header.classList.remove('scrolled');
			}
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

ui_scroll.init();

window.addEventListener('scroll', function() {
	ui_scroll.init();				
});